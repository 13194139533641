import cn from 'classnames';

import {getAgreement, getResearchAgreement} from '@/components/common/forms/request-form/helpers';
import TickIcon from '@/components/common/icons/tick.svg';

import styles from './AgreementBlock.module.css';

interface AgreementBlockProps {
	value: boolean;
	onChange: () => void;
	className?: string;
	researchForm?: boolean;
}

export function AgreementBlock({value, onChange, className, researchForm = false}: AgreementBlockProps) {
	return (
		<div className={cn(styles.agreementBlock, className)} onClick={onChange}>
			<div className={styles.icon}>{value ? <TickIcon /> : <div className={styles.noAgreement} />}</div>
			<div className={styles.agreementText}>
				{researchForm ? getResearchAgreement() : getAgreement()}
			</div>
		</div>
	);
}
